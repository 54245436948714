<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card title="Talep Oluştur">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <marketing-request-users-card />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <marketing-request-title />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <marketing-request-subject-card />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <marketing-request-status-code-card />
            </b-col>
            <!--            <b-col-->
            <!--              cols="12"-->
            <!--              md="6"-->
            <!--            >-->
            <!--              <marketing-request-s-date-card />-->
            <!--            </b-col>-->
            <!--            <b-col-->
            <!--              cols="12"-->
            <!--              md="6"-->
            <!--            >-->
            <!--              <marketing-request-e-date-card />-->
            <!--            </b-col>-->
            <b-col
              cols="12"
              md="12"
            >
              <marketing-request-content-card />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
// import MarketingRequestSDateCard from '@/views/Marketing/Requests/Elements/SdateCard.vue'
// import MarketingRequestEDateCard from '@/views/Marketing/Requests/Elements/EdateCard.vue'
import MarketingRequestContentCard from '@/views/Marketing/Requests/Elements/ContentCard.vue'
import MarketingRequestSubjectCard from '@/views/Marketing/Requests/Elements/SubjectCard.vue'
import MarketingRequestStatusCodeCard from '@/views/Marketing/Requests/Elements/StatusCodeCard.vue'
import MarketingRequestUsersCard from '@/views/Marketing/Requests/Elements/userCard.vue'
import MarketingRequestTitle from '@/views/Marketing/Requests/Elements/TitleCard.vue'

export default {
  name: 'MarketingRequestAddItemform',
  components: {
    MarketingRequestTitle,
    MarketingRequestUsersCard,
    MarketingRequestStatusCodeCard,
    MarketingRequestSubjectCard,
    MarketingRequestContentCard,
    // MarketingRequestEDateCard,
    // MarketingRequestSDateCard,
    BCard,
    BRow,
    BCol,
  },
  computed: {
    itemData() {
      return this.$store.getters['marketingRequest/dataItem']
    },
  },
}
</script>
