<template>
  <b-form-group
    label="Talep Eden Kullanıcı"
    label-for="id_com_user"
  >
    <validation-provider
      #default="{ errors }"
      name="Talep Eden Kullanıcı"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_com_user"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Giriniz"
        :disabled="userData.id_com_department !== '16' && userData.id_com_department !== '24'"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'MarketingRequestUsersCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['users/getUsers']
    },
    dataItem() {
      const data = this.$store.getters['marketingRequest/dataItem']
      if (this.userData.id_com_department !== 16) {
        data.id_com_user = this.userData.id
      }
      return data
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
        ],
      })
    },
  },
}
</script>
